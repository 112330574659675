/* TL.TimeMarker
================================================== */

.tl-timemarker {
	height:100%;
	position:absolute;
	top:0;
	left:0;
	cursor:pointer;
	
	/* Animate Left Width and Top
	================================================== */
	.property-animation(left, @animation-duration, @animation-ease);
	.property-animation(top, @animation-duration-fast, @animation-ease);
	.property-animation(height, @animation-duration-fast, @animation-ease);
	.property-animation(width, @animation-duration, @animation-ease);
	.animation-timing-cubic-bezier();
	

	&.tl-timemarker-fast {
		.property-animation(top, @animation-duration-fast, @animation-ease);
		.property-animation(height, @animation-duration-fast, @animation-ease);
		.property-animation(width, @animation-duration-fast, @animation-ease);
		.animation-timing-cubic-bezier();

		.tl-timemarker-content-container {
			.property-animation(width, @animation-duration-fast, @animation-ease);
			.property-animation(height, @animation-duration-fast, @animation-ease);
		}
		.tl-timemarker-timespan {
			.property-animation(height, @animation-duration-fast, @animation-ease);
			.property-animation(width, @animation-duration-fast, @animation-ease);
		}
	}
	/* Timespan
	================================================== */
	.tl-timemarker-timespan {
		pointer-events: none;
		position:absolute;
		margin:0;
		width:100%;
		height:100%;
		//overflow:hidden;
		background-color: fadeout(@marker-color, 85%);
		border-top-right-radius:@time-marker-border-radius;
		border-top-left-radius:@time-marker-border-radius;
		
		.property-animation(height, @animation-duration-fast, @animation-ease);
		.property-animation(width, @animation-duration, @animation-ease);
		.animation-timing-cubic-bezier();
		//box-shadow: 1px 1px 1px @color-background;
		//box-sizing: border-box;
		.tl-timemarker-timespan-content {
			display:none;
			position:absolute;
			width:100%;
			background-color: @marker-color;
			border-top-left-radius:@time-marker-border-radius;
			border-top-right-radius:@time-marker-border-radius;
			height:100px;
			box-sizing: border-box;
			
			
		}
		
	}
	
	/* Lines
	================================================== */
	.tl-timemarker-line-right {
		display:none;
		right:0px;
		
	}
	
	.tl-timemarker-line-left {
		width:1px;
		left:0px;
	}
	
	.tl-timemarker-line-left, .tl-timemarker-line-right {
		margin-top:@marker-dot-offset;
		//margin-bottom:@time-marker-border-radius;
		//margin-top:10px; 
		//overflow:hidden;
		//border-top-right-radius:@time-marker-border-radius;
		//border-top-left-radius:@time-marker-border-radius;
		//margin-top:10%;
		.border-box();
		border-left: 1px solid @marker-outline-color;
		
		z-index:5;
		//.border-top-radius(10px);
		content:" ";
		position:absolute;
		//bottom:0;
		height:100%;
		.disable-selection();
		
		box-shadow: 1px 1px 1px @color-background;
		
		&:after {
			
			display:block;
			content:" ";
			position:absolute;
			left:-4px;
			bottom:0px;
			height:6px;
			width:6px;
			background-color:@marker-dot-color;
			z-index:8;
			.border-radius(50%);
		}
	}
	
	/* Content
	================================================== */
	.tl-timemarker-content-container {
		position:absolute;
		background-color: @marker-color;
		border:0;
		border-top-left-radius:@time-marker-border-radius;
		border-top-right-radius:@time-marker-border-radius;
		border-bottom-right-radius:@time-marker-border-radius;
		height:100%;
		width:100px;
		overflow:hidden;
		z-index:6;
		&:hover {
			z-index: 9;
		}
		.property-animation(height, @animation-duration-fast, @animation-ease);
		.property-animation(width, @animation-duration, @animation-ease);
		.animation-timing-cubic-bezier();
		box-sizing: border-box;
		border: 1px solid @marker-outline-color;
		box-shadow: 1px 1px 1px @color-background;
				
		.tl-timemarker-content {
			position:relative;
			overflow:hidden;
			height:100%;
			z-index:8;
			padding:5px;
			.border-box();
			
			.tl-timemarker-text {
				overflow:hidden;
				position: relative;
				
				h2.tl-headline, h2.tl-headline p {
					display: -webkit-box;
					
					line-clamp: 2;
					-webkit-line-clamp: 2;
					box-orient: vertical;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					
					font-size:12px;
					line-height:12px;
					height:100%;
					overflow:hidden;
					font-weight:normal;
					margin:0;
					color:@marker-text-color;
					position: relative;
					&.tl-headline-fadeout {
						&:after {
							content: "";
							text-align: right;
							position: absolute;
							bottom: 0;
							right: 0;
							width: 100%;
							height: 50%;
							.marker-text-fade(@marker-color,50%);
						}
					}
					
				}
				
			}
			
			.tl-timemarker-media-container {
				float:left;
				max-width:@marker-icon-size;
				max-height:@marker-icon-size;
				overflow:hidden;
				margin-right:5px;
				height:100%;
				.border-box();
				
				.tl-timemarker-media {
					max-width:@marker-icon-size;
					max-height:100%;
					opacity:0.25;
				}
				
				[class^="tl-icon-"], [class*=" tl-icon-"] {
					display:block;
					font-size:@marker-icon-size;
					color:@marker-text-color;
					margin-top:0px;
				}
				
				.tl-icon-wikipedia {
					font-size:16px;
				}
				
			}
			
			&.tl-timemarker-content-small {
				.tl-timemarker-text {
					h2.tl-headline {
						display:block;
						white-space:nowrap; 
						text-overflow: ellipsis;
					}
				}
				.tl-timemarker-media-container {
					[class^="tl-icon-"], [class*=" tl-icon-"] {
						font-size:@marker-icon-size/2;
					}
				}
				
			}
			
		}
		

		
	}
	
	/* Hover State
	================================================== */
	&:hover {
		
		.tl-timemarker-timespan {
			background-color: fadeout(@marker-text-color, 85%);
			
			.tl-timemarker-timespan-content {
				background-color: @marker-text-color;
			}
			
			.tl-timemarker-line-left, .tl-timemarker-line-right {
				border-color: darken(@marker-color,25);
				&:after {
					background-color:@marker-dot-hover-color;
				}
			}
			

			
		}
		
		.tl-timemarker-content-container {
			background-color: darken(@marker-color,15);
			border-color: darken(@marker-color,25);
			.property-animation(height, @animation-duration-fast/2, @animation-ease);
			.property-animation(width, @animation-duration-fast/2, @animation-ease);
			&.tl-timemarker-content-container-small {
				//height:56px !important;
				width:200px;
				//min-width:100px;
				.tl-timemarker-content {
					.tl-timemarker-text {
						h2.tl-headline {
							//line-clamp:3 !important;
							//-webkit-line-clamp:3 !important;
							//white-space: normal !important;
							
						}
					}
				}
			}
			
			.tl-timemarker-content {
				
				.tl-timemarker-text {
					h2.tl-headline {
						color:@marker-selected-text-color;
						&.tl-headline-fadeout {
							&:after {
								.marker-text-fade(darken(@marker-color,15),80%);
							}
						}
						
					}
				}
				
				.tl-timemarker-media-container {
					.tl-timemarker-media {
						opacity:1;
					}
					[class^="tl-icon-"], [class*=" tl-icon-"] {
						color:@marker-selected-text-color;
					}
				}
			}
		}
		
	}
	
	/* Hover Active State
	================================================== */
	&:hover {
		&.tl-timemarker-active {
			.tl-timemarker-content-container {
				.tl-timemarker-content {
					.tl-timemarker-text {
						h2.tl-headline {
							&.tl-headline-fadeout {
								&:after {
									//.marker-text-fade(@color-theme,80%);
									.marker-text-fade(@color-background,80%);
								}
							}
							
						}
					}
				}
			}
			.tl-timemarker-line-left, .tl-timemarker-line-right {
				//border-color:@color-theme;
				border-color:@color-dark;
			
				&:after {
					//z-index:6;
					//background-color:@color-theme;
					background-color:@color-dark;
				}
			}
		}
	}
	
	/* Active Markers
	================================================== */
	&.tl-timemarker-active {
		
		.tl-timemarker-timespan {
			//background-color: fadeout(@color-theme, 85%);
			//background-color: fadeout(@color-foreground, 85%);
			background-color: fadeout(@color-background,50%);
			z-index: 8;
			.tl-timemarker-timespan-content {
				//background-color: @color-theme;
				background-color: @color-foreground;
			}
		}
		
		.tl-timemarker-line-left, .tl-timemarker-line-right {
			//border-color:@color-theme;
			//border-color:@color-foreground;
			border-color:fadeout(@color-foreground, 50%);
			border-width: 1px;
			z-index:8;
			box-shadow: 0px 1px 3px fadeout(@marker-dot-color, 50%);
			&:after {
				
				//background-color:@color-theme;
				background-color:@color-foreground;
			}
		}
		
		.tl-timemarker-content-container {
			//background-color:@color-theme;
			background-color:@color-background;
			//color:@color-background;
			color:@color-foreground;
			z-index:9;
			//border: none;
			border-color:fadeout(@color-foreground, 50%);
			
			//box-shadow:none;
			box-shadow: 1px 1px 3px fadeout(@marker-dot-color, 50%);
			.tl-timemarker-content {
				.tl-timemarker-text {
					h2.tl-headline {
						//color:@marker-selected-text-color;
						color:@color-foreground; 
						&.tl-headline-fadeout {
							&:after {
								//.marker-text-fade(@color-theme,80%);
								.marker-text-fade(@color-background,80%);
							}
						}
						
					}
				}
				
				.tl-timemarker-media-container {
					.tl-timemarker-media {
						opacity:1;
					}
					[class^="tl-icon-"], [class*=" tl-icon-"] {
						//color:lighten(@color-theme,33);
						color:@color-foreground;
					}
				}
			}
		}
	}
	
	/* Markers with End Dates
	================================================== */
	&.tl-timemarker-with-end {
		
		.tl-timemarker-timespan {
			.tl-timemarker-timespan-content {
				display:block;
			}
			
			.tl-timemarker-line-left, .tl-timemarker-line-right {
				z-index:5;
				&:after {
					
				}
			}
			
			&:after {
				display:block;
				content:" ";
				position:absolute;
				left:0px;
				bottom:-@marker-dot-offset;
				height:6px;
				width:100%;
				background-color: fadeout(darken(@ui-background-color, 50), 85%);
				z-index:6;
				.border-radius(7px);
			}
			
		}
		
		.tl-timemarker-content-container {
			
			&.tl-timemarker-content-container-long {
				box-shadow: none;
			}
			.tl-timemarker-content {
				
			}
		}
		
		.tl-timemarker-line-right {
			display:block;
		}
		.tl-timemarker-line-left {
			box-shadow: none;
		}
		
		.tl-timemarker-line-left, .tl-timemarker-line-right {
			
		}
	}
	
	/* Markers with End Dates and Hover
	================================================== */
	&.tl-timemarker-with-end {
		&:hover {
			
			.tl-timemarker-timespan {
				&:after {
					background-color: fadeout(darken(@ui-background-color,100),75%);
				}
			}
			.tl-timemarker-line-left, .tl-timemarker-line-right {
				&:after {
					
				}
			}
			
		}
	}
	
	/* Markers with End Dates and Active
	================================================== */
	&.tl-timemarker-with-end {
		&.tl-timemarker-active {
			.tl-timemarker-timespan {
				&:after {
					//background-color: fadeout(@color-theme,50%);
					background-color: fadeout(@color-foreground,50%);
				}
			}
			
			.tl-timemarker-line-left, .tl-timemarker-line-right {
				border-width: 1px;
				&:after {
					//background-color:@color-theme !important;
					background-color:@color-foreground !important;
				}
			}
			.tl-timemarker-line-left {
				box-shadow: none;
			}
		}
	}
	
	/* Markers with End Dates and Active and Hover
	================================================== */
	&.tl-timemarker-with-end {
		&.tl-timemarker-active {
			&:hover {
				.tl-timemarker-timespan {
					&:after {
						//background-color: fadeout(@color-theme,50%);
						background-color: fadeout(@color-foreground,50%);
					}
				}
			
			}
		}
	}
}

/* Skinny
================================================== */
.tl-skinny {
	.tl-timemarker {

	}
}

/* Mobile, iPhone
================================================== */
.tl-mobile {
	.tl-timemarker {

	}
}

/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny {
	.tl-timemarker {

	}
}
