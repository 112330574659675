/*!
	Timeline JS 3 
	
	Designed and built by Zach Wise for the Northwestern University Knight Lab
	
	This Source Code Form is subject to the terms of the Mozilla Public
	License, v. 2.0. If a copy of the MPL was not distributed with this
	file, You can obtain one at https://mozilla.org/MPL/2.0/.

*/
/* Includes 
================================================== */
/*	VARIABLES
----------------------------------------------------- */
/* ICON PATH
================================================== */
/* TYPEFACE
================================================== */
/* COLOR SCHEME
================================================== */
/* UI COLOR
================================================== */
/* UI
================================================== */
/* Animation
================================================== */
/* GFX
================================================== */
/*!
	Timeline JS 3

	Designed and built by Zach Wise for the Northwestern University Knight Lab

	This Source Code Form is subject to the terms of the Mozilla Public
	License, v. 2.0. If a copy of the MPL was not distributed with this
	file, You can obtain one at https://mozilla.org/MPL/2.0/.

*/
/* Includes
================================================== */
/*	Mixins.less
	Snippets of reusable CSS to develop faster and keep code readable
 * ----------------------------------------------------------------- */
/*	Reset
------------------------------------------------------------------------------------------- */
.tl-storyjs {
  /*	Reset tags and common classes
		Display in IE6-9 and FF3
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
  /*	Prevents modern browsers from displaying 'audio' without controls
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
  /*	Prevents sub and sup affecting line-height in all browsers
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
  /*	Img border in a's and image quality
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
  /*	Forms
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
}
.tl-storyjs div * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.tl-storyjs h1,
.tl-storyjs h2,
.tl-storyjs h3,
.tl-storyjs h4,
.tl-storyjs h5,
.tl-storyjs h6,
.tl-storyjs p,
.tl-storyjs blockquote,
.tl-storyjs pre,
.tl-storyjs a,
.tl-storyjs abbr,
.tl-storyjs acronym,
.tl-storyjs address,
.tl-storyjs cite,
.tl-storyjs code,
.tl-storyjs del,
.tl-storyjs dfn,
.tl-storyjs em,
.tl-storyjs img,
.tl-storyjs q,
.tl-storyjs s,
.tl-storyjs samp,
.tl-storyjs small,
.tl-storyjs strike,
.tl-storyjs strong,
.tl-storyjs sub,
.tl-storyjs sup,
.tl-storyjs tt,
.tl-storyjs var,
.tl-storyjs dd,
.tl-storyjs dl,
.tl-storyjs dt,
.tl-storyjs li,
.tl-storyjs ol,
.tl-storyjs ul,
.tl-storyjs fieldset,
.tl-storyjs form,
.tl-storyjs label,
.tl-storyjs legend,
.tl-storyjs button,
.tl-storyjs table,
.tl-storyjs caption,
.tl-storyjs tbody,
.tl-storyjs tfoot,
.tl-storyjs thead,
.tl-storyjs tr,
.tl-storyjs th,
.tl-storyjs td,
.tl-storyjs .tl-container,
.tl-storyjs .content-container,
.tl-storyjs .media,
.tl-storyjs .text,
.tl-storyjs .tl-slider,
.tl-storyjs .slider,
.tl-storyjs .date,
.tl-storyjs .title,
.tl-storyjs .message,
.tl-storyjs .map,
.tl-storyjs .credit,
.tl-storyjs .caption,
.tl-storyjs .tl-feedback,
.tl-storyjs .tl-feature,
.tl-storyjs .toolbar,
.tl-storyjs .marker,
.tl-storyjs .dot,
.tl-storyjs .line,
.tl-storyjs .flag,
.tl-storyjs .time,
.tl-storyjs .era,
.tl-storyjs .major,
.tl-storyjs .minor,
.tl-storyjs .tl-navigation,
.tl-storyjs .start,
.tl-storyjs .active {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
  width: auto;
  float: none;
}
.tl-storyjs h1,
.tl-storyjs h2,
.tl-storyjs h3,
.tl-storyjs h4,
.tl-storyjs h5,
.tl-storyjs h6 {
  clear: none;
}
.tl-storyjs table {
  border-collapse: collapse;
  border-spacing: 0;
}
.tl-storyjs ol,
.tl-storyjs ul {
  list-style: none;
}
.tl-storyjs q:before,
.tl-storyjs q:after,
.tl-storyjs blockquote:before,
.tl-storyjs blockquote:after {
  content: "";
}
.tl-storyjs a:focus {
  outline: thin dotted;
}
.tl-storyjs a:hover,
.tl-storyjs a:active {
  outline: 0;
}
.tl-storyjs article,
.tl-storyjs aside,
.tl-storyjs details,
.tl-storyjs figcaption,
.tl-storyjs figure,
.tl-storyjs footer,
.tl-storyjs header,
.tl-storyjs hgroup,
.tl-storyjs nav,
.tl-storyjs section {
  display: block;
}
.tl-storyjs audio,
.tl-storyjs canvas,
.tl-storyjs video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
.tl-storyjs audio:not([controls]) {
  display: none;
}
.tl-storyjs div {
  max-width: none;
}
.tl-storyjs sub,
.tl-storyjs sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.tl-storyjs sup {
  top: -0.5em;
}
.tl-storyjs sub {
  bottom: -0.25em;
}
.tl-storyjs img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
.tl-storyjs button,
.tl-storyjs input,
.tl-storyjs select,
.tl-storyjs textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
.tl-storyjs button,
.tl-storyjs input {
  line-height: normal;
  *overflow: visible;
}
.tl-storyjs button::-moz-focus-inner,
.tl-storyjs input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.tl-storyjs button,
.tl-storyjs input[type="button"],
.tl-storyjs input[type="reset"],
.tl-storyjs input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
.tl-storyjs input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.tl-storyjs input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.tl-storyjs textarea {
  overflow: auto;
  vertical-align: top;
}
.tl-timeline {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* VCard
	================================================== */
}
.tl-timeline h1,
.tl-timeline h2,
.tl-timeline h3,
.tl-timeline h4,
.tl-timeline h5,
.tl-timeline h6 {
  color: #000;
}
.tl-timeline h1,
.tl-timeline h2,
.tl-timeline h3 {
  font-size: 28px;
  line-height: 28px;
}
.tl-timeline h1 small,
.tl-timeline h2 small,
.tl-timeline h3 small {
  font-size: 24px;
  line-height: 24px;
}
.tl-timeline h4,
.tl-timeline h5,
.tl-timeline h6 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 0px;
}
.tl-timeline h4 small,
.tl-timeline h5 small,
.tl-timeline h6 small {
  font-size: 15px;
  line-height: 15px;
}
.tl-timeline h2.tl-headline-title {
  font-size: 38px;
  line-height: 38px;
}
.tl-timeline h2.tl-headline-title small {
  display: block;
  margin-top: 5px;
  font-size: 24px;
  line-height: 24px;
}
.tl-timeline h2 {
  margin-top: 20px;
  margin-bottom: 5px;
}
.tl-timeline p {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #666666;
}
.tl-timeline p.lead {
  font-size: 24px;
}
.tl-timeline p a {
  /*
			color: lighten(@color-dark, 40%);
			text-decoration: none;
			background-image: -moz-linear-gradient(top, fade(lighten(@color-dark, 40%), 0%) 50%, fade(lighten(@color-dark, 40%), 60%) 50%);
			background-image: -webkit-linear-gradient(top, fade(lighten(@color-dark, 40%), 0%) 50%, fade(lighten(@color-dark, 40%), 60%) 50%);
			background-image: -o-linear-gradient(top, fade(lighten(@color-dark, 40%), 0%) 50%, fade(lighten(@color-dark, 40%), 60%) 50%);
			background-image: linear-gradient(to bottom, fade(lighten(@color-dark, 40%), 0%) 50%, fade(lighten(@color-dark, 40%), 60%) 50%);
			background-repeat: repeat-x;
			background-size: 2px 2px;
			background-position: 0 @base-font-size+2;
			text-shadow: -2px -1px 0 white, 2px -1px 0 white, -2px 1px 0 white, 2px 1px 0 white;
			&:hover,
			&:focus {
				color:@color-theme;
				text-decoration: none;
			}
			*/
  color: #666666;
  text-decoration: underline;
}
.tl-timeline p a:hover,
.tl-timeline p a:focus {
  color: #c34528;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .tl-timeline p a {
    text-decoration: underline;
    background-image: none;
    text-shadow: none;
  }
  .tl-timeline p a:hover,
  .tl-timeline p a:focus {
    color: #c34528;
    text-decoration: underline;
  }
}
.tl-timeline b,
.tl-timeline strong {
  font-weight: bold;
}
.tl-timeline i,
.tl-timeline em {
  font-style: italic;
}
.tl-timeline a {
  text-decoration: none;
  color: #c34528;
}
.tl-timeline a:hover {
  text-decoration: underline;
  color: #6e2717;
}
.tl-timeline .tl-caption,
.tl-timeline .tl-credit,
.tl-timeline .tl-slidenav-next,
.tl-timeline .tl-slidenav-previous {
  font-size: 11px;
  line-height: 11px;
}
.tl-timeline .tl-caption a,
.tl-timeline .tl-credit a,
.tl-timeline .tl-slidenav-next a,
.tl-timeline .tl-slidenav-previous a {
  color: #000;
}
.tl-timeline .tl-makelink {
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.tl-timeline blockquote,
.tl-timeline blockquote p {
  font-family: "Georgia", "Times New Roman", Times, serif;
  color: #999999;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 0px;
  padding: 0px;
}
.tl-timeline blockquote cite,
.tl-timeline blockquote p cite {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  color: #666666;
  display: block;
  text-align: right;
  font-style: normal;
}
.tl-timeline blockquote cite:before,
.tl-timeline blockquote p cite:before {
  content: "\2014";
}
.tl-timeline blockquote p:before {
  content: open-quote;
  display: inline-block;
  font-size: 28px;
  position: relative;
  top: 8px;
  margin-right: 5px;
}
.tl-timeline blockquote p:after {
  content: close-quote;
  display: inline-block;
  font-size: 28px;
  position: relative;
  top: 8px;
  margin-left: 3px;
}
.tl-timeline blockquote {
  margin: 10px;
}
.tl-timeline blockquote p {
  margin: 0;
}
.tl-timeline .vcard {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 15px;
  *zoom: 1;
  margin-bottom: 15px;
  margin-top: 10px;
}
.tl-timeline .vcard:before,
.tl-timeline .vcard:after {
  display: table;
  content: "";
}
.tl-timeline .vcard:after {
  clear: both;
}
.tl-timeline .vcard .twitter-date {
  text-align: left;
  font-size: 11px;
}
.tl-timeline .vcard .author {
  float: right;
}
.tl-timeline .vcard a {
  color: #333333;
  text-decoration: none;
}
.tl-timeline .vcard a:hover {
  text-decoration: none;
}
.tl-timeline .vcard a:hover .fn,
.tl-timeline .vcard a:hover .nickname {
  color: #c34528;
}
.tl-timeline .vcard .fn,
.tl-timeline .vcard .nickname {
  padding-left: 42px;
}
.tl-timeline .vcard .fn {
  display: block;
  font-weight: bold;
}
.tl-timeline .vcard .nickname {
  margin-top: 1px;
  display: block;
  color: #666666;
}
.tl-timeline .vcard .avatar {
  float: left;
  display: block;
  width: 32px;
  height: 32px;
}
.tl-timeline .vcard .avatar img {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.tl-timeline .tl-text ul {
  padding: 0px;
  padding-left: 30px;
  margin: 0;
}
.tl-timeline .tl-text ul li {
  margin-bottom: 5px;
}
.tl-timeline .tl-button-calltoaction {
  cursor: pointer;
  font-weight: bold;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.tl-timeline .tl-button-calltoaction .tl-button-calltoaction-text {
  display: inline-block;
  background-color: #c34528;
  color: #fff;
  padding: 10px 15px 10px 15px;
  border-radius: 7px;
}
.tl-timeline .tl-note {
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  background-color: #e6e6e6;
  font-size: 15px;
  line-height: 17px;
  padding: 10px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  text-shadow: none;
}
@media only screen and (max-width: 800px), only screen and (max-device-width: 800px) {
  .tl-timeline h1,
  .tl-timeline h2,
  .tl-timeline h3 {
    font-size: 28px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 500px), only screen and (max-device-width: 500px) {
  .tl-timeline h1,
  .tl-timeline h2,
  .tl-timeline h3 {
    font-size: 24px;
    line-height: 24px;
  }
}
.tl-skinny h2 {
  margin-top: 0px;
}
/* Icons
================================================== */
@font-face {
  font-family: 'tl-icons';
  src: url('../css/icons/tl-icons.eot');
  src: url('../css/icons/tl-icons.eot?#iefix') format('embedded-opentype'), url('../css/icons/tl-icons.ttf') format('truetype'), url('../css/icons/tl-icons.woff2') format('woff2'), url('../css/icons/tl-icons.woff') format('woff'), url('../css/icons/tl-icons.svg#tl-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="tl-icon-"],
[class*=" tl-icon-"] {
  font-family: 'tl-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tl-icon-vine:after {
  content: "\e64d";
}
.tl-icon-wikipedia:after {
  content: "\e64e";
}
.tl-icon-chevron-right:after {
  content: "\e64f";
}
.tl-icon-chevron-left:after {
  content: "\e650";
}
.tl-icon-youtube-logo:after {
  content: "\e651";
}
.tl-icon-foursquare:after {
  content: "\e652";
}
.tl-icon-camera-retro:after {
  content: "\e653";
}
.tl-icon-doc:after {
  content: "\e654";
}
.tl-icon-weibo:after {
  content: "\e655";
}
.tl-icon-resize-horizontal:after {
  content: "\e656";
}
.tl-icon-resize-vertical:after {
  content: "\e657";
}
.tl-icon-resize-full:after {
  content: "\e658";
}
.tl-icon-resize-small:after {
  content: "\e659";
}
.tl-icon-twitter:after {
  content: "\e62b";
}
.tl-icon-google-plus:after {
  content: "\e62c";
}
.tl-icon-video:after {
  content: "\e62d";
}
.tl-icon-youtube:after {
  content: "\e62d";
}
.tl-icon-plaintext:after {
  content: "\e62e";
}
.tl-icon-storify:after {
  content: "\e62e";
}
.tl-icon-image-v2:after {
  content: "\e62f";
}
.tl-icon-quote-v2:after {
  content: "\e630";
}
.tl-icon-zoom-in:after {
  content: "\e631";
}
.tl-icon-zoom-out:after {
  content: "\e632";
}
.tl-icon-list:after {
  content: "\e633";
}
.tl-icon-music:after {
  content: "\e634";
}
.tl-icon-spotify:after {
  content: "\e634";
}
.tl-icon-location:after {
  content: "\e635";
}
.tl-icon-googlemaps:after {
  content: "\e635";
}
.tl-icon-web:after {
  content: "\e636";
}
.tl-icon-share-v2:after {
  content: "\e637";
}
.tl-icon-soundcloud:after {
  content: "\e639";
}
.tl-icon-video-v2:after {
  content: "\e63a";
}
.tl-icon-dailymotion:after {
  content: "\e63a";
}
.tl-icon-tumblr:after {
  content: "\e63b";
}
.tl-icon-lastfm:after {
  content: "\e63c";
}
.tl-icon-github:after {
  content: "\e63d";
}
.tl-icon-goback:after {
  content: "\e63e";
}
.tl-icon-doc-v2:after {
  content: "\e63f";
}
.tl-icon-googledrive:after {
  content: "\e640";
}
.tl-icon-facebook:after {
  content: "\e641";
}
.tl-icon-flickr:after {
  content: "\e642";
}
.tl-icon-dribbble:after {
  content: "\e643";
}
.tl-icon-image:after {
  content: "\e605";
}
.tl-icon-vimeo:after {
  content: "\e606";
}
.tl-icon-instagram:after {
  content: "\e644";
}
.tl-icon-pinterest:after {
  content: "\e645";
}
.tl-icon-arrow-left:after {
  content: "\e646";
}
.tl-icon-arrow-down:after {
  content: "\e647";
}
.tl-icon-arrow-up:after {
  content: "\e648";
}
.tl-icon-arrow-right:after {
  content: "\e649";
}
.tl-icon-share:after {
  content: "\e64a";
}
.tl-icon-blockquote:after {
  content: "\e64b";
}
.tl-icon-evernote:after {
  content: "\e64c";
}
.tl-icon-mappin:after {
  content: "\e600";
}
.tl-icon-swipe-right:after {
  content: "\e601";
}
.tl-icon-swipe-left:after {
  content: "\e602";
}
.tl-icon-touch-spread:after {
  content: "\e603";
}
.tl-icon-touch-pinch:after {
  content: "\e604";
}
/* Disable Text selection when dragging
================================================== */
.tl-dragging {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
/* MenuBar 
================================================== */
.tl-menubar {
  position: absolute;
  z-index: 11;
  text-align: center;
  color: #333;
  overflow: hidden;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  top: 100%;
  left: 50%;
  left: 0;
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
/* Color
================================================== */
/*
.tl-sizebar.tl-sizebar-inverted {
	border-bottom: 1px solid #FFF;
	//background-color:#000;
	color:#a5a5a5;
	.tl-sizebar-button {
		border-left: 1px solid darken(@color-background, 70);
		//color:#a5a5a5;
	}
	.tl-sizebar-button:hover {
		//background:@color-theme;
		color:@color-background;
	}
}
.tl-sizebar.tl-sizebar-inverted:before {
	background-color:#000;
	//.gradient-vertical (rgba(0,0,0,0.25), rgba(0,0,0,1));
	//.translucent-background(rgb(0,0,0), .5);
	border-top: 2px solid #000;
	animation: invertToBlack 1s;
	-webkit-animation:invertToBlack 1s; 
}
*/
@keyframes invertToBlack {
  from {
    background-color: #FFF;
  }
  to {
    background-color: #000;
  }
}
@-webkit-keyframes invertToBlack {
  from {
    background: #FFF;
  }
  to {
    background: #000;
  }
}
@keyframes invertToWhite {
  from {
    background-color: #000;
  }
  to {
    background-color: #FFF;
  }
}
@-webkit-keyframes invertToWhite {
  from {
    background: #000;
  }
  to {
    background: #FFF;
  }
}
/* MenuBar Button
================================================== */
.tl-menubar-button {
  font-size: 18px;
  line-height: 18px;
  background-color: rgba(242, 242, 242, 0.9);
  cursor: pointer;
  padding: 6px 12px 6px 12px;
  display: inline-block;
  display: block;
  color: #bfbfbf;
}
.tl-menubar-button.tl-menubar-button-inactive {
  opacity: 0.33;
}
.tl-menubar-button:hover {
  background: #333;
  color: #FFF;
}
.tl-menubar-button:hover.tl-menubar-button-inactive {
  color: #bfbfbf;
  background-color: rgba(242, 242, 242, 0.9);
}
.tl-mobile .tl-menubar-button {
  display: block;
}
.tl-mobile .tl-menubar-button:hover {
  background-color: rgba(242, 242, 242, 0.67);
  color: #737373;
}
.tl-mobile .tl-menubar-button:active {
  background: #c34528;
  color: #FFF;
}
@keyframes invertToBlack {
  from {
    background-color: #FFF;
  }
  to {
    background-color: #000;
  }
}
@-webkit-keyframes invertToBlack {
  from {
    background: #FFF;
  }
  to {
    background: #000;
  }
}
@keyframes invertToWhite {
  from {
    background-color: #000;
  }
  to {
    background-color: #FFF;
  }
}
@-webkit-keyframes invertToWhite {
  from {
    background: #000;
  }
  to {
    background: #FFF;
  }
}
/* MESSAGE 
================================================== */
.tl-message,
.tl-message-full {
  width: 100%;
  height: 100%;
  position: absolute;
  display: table;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 99;
  margin: auto;
  text-align: center;
}
.tl-message .tl-message-container,
.tl-message-full .tl-message-container {
  padding: 20px;
  margin: 20px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.tl-message .tl-message-container .tl-message-content,
.tl-message-full .tl-message-container .tl-message-content {
  color: #666;
  text-align: center;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  text-shadow: 1px 1px 1px #FFF;
}
.tl-message .tl-message-container .tl-message-content strong,
.tl-message-full .tl-message-container .tl-message-content strong {
  text-transform: uppercase;
}
.tl-message .tl-message-container .tl-loading-icon,
.tl-message-full .tl-message-container .tl-loading-icon {
  width: 30px;
  height: 30px;
  background-color: #666;
  vertical-align: middle;
  -webkit-box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.1);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
  animation: rotateplane 1.2s infinite ease-in-out;
}
@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.tl-message-full {
  background-color: hsla(0, 0%, 100%, 0.8);
}
.tl-message-full [class^="tl-icon-"],
.tl-message-full [class*=" tl-icon-"] {
  color: #666;
  font-size: 72px;
}
.tl-message-full .tl-message-container .tl-message-content {
  font-size: 22px;
  line-height: 22px;
  text-shadow: none;
  color: #666;
  text-transform: none;
  font-weight: normal;
}
.tl-message-full .tl-message-container .tl-message-content .tl-button {
  display: inline-block;
  cursor: pointer;
  background-color: #FFF;
  color: #333;
  padding: 10px;
  margin-top: 10px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
/* TL.TimeNav
================================================== */
.tl-timenav {
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
}
.tl-timenav .tl-attribution {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  bottom: 2px;
  left: 0px;
  font-size: 10px;
  line-height: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 3px;
  /*
		right:-26px;
		top:30px;
		transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		background-color: fadeout(@ui-background-color, 15%);
		*/
}
.tl-timenav .tl-attribution a {
  color: #cccccc;
}
.tl-timenav .tl-attribution a:hover {
  color: #000;
  text-decoration: none;
}
.tl-timenav .tl-attribution a:hover .tl-knightlab-logo {
  background-color: #c34528;
}
.tl-timenav .tl-attribution .tl-knightlab-logo {
  display: inline-block;
  vertical-align: middle;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  background-color: #c34528;
  background-color: #cccccc;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.tl-timenav .tl-timenav-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  z-index: 2;
  display: none;
}
.tl-timenav .tl-timenav-line:before,
.tl-timenav .tl-timenav-line:after {
  font-family: 'tl-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c34528;
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  left: -14px;
}
.tl-timenav .tl-timenav-line:before {
  top: -10px;
}
.tl-timenav .tl-timenav-line:after {
  content: "\e648";
  bottom: 24px;
}
.tl-timenav .tl-timenav-slider {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.tl-timenav .tl-timenav-slider.tl-timenav-slider-animate {
  -webkit-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timenav .tl-timenav-slider .tl-timenav-slider-background {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: move;
  z-index: 6;
}
.tl-timenav .tl-timenav-slider .tl-timenav-container-mask {
  position: absolute;
  height: 100%;
  top: 0;
}
.tl-timenav .tl-timenav-slider .tl-timenav-container-mask .tl-timenav-container {
  position: absolute;
  height: 100%;
}
.tl-timenav .tl-timenav-slider .tl-timenav-container-mask .tl-timenav-container .tl-timenav-item-container {
  position: absolute;
  height: 100%;
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
/* TL.TimeMarker
================================================== */
.tl-timemarker {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  /* Animate Left Width and Top
	================================================== */
  -webkit-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* Timespan
	================================================== */
  /* Lines
	================================================== */
  /* Content
	================================================== */
  /* Hover State
	================================================== */
  /* Hover Active State
	================================================== */
  /* Active Markers
	================================================== */
  /* Markers with End Dates
	================================================== */
  /* Markers with End Dates and Hover
	================================================== */
  /* Markers with End Dates and Active
	================================================== */
  /* Markers with End Dates and Active and Hover
	================================================== */
}
.tl-timemarker.tl-timemarker-fast {
  -webkit-transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timemarker.tl-timemarker-fast .tl-timemarker-content-container {
  -webkit-transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timemarker.tl-timemarker-fast .tl-timemarker-timespan {
  -webkit-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timemarker .tl-timemarker-timespan {
  pointer-events: none;
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(229, 229, 229, 0.15);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timemarker .tl-timemarker-timespan .tl-timemarker-timespan-content {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #e5e5e5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 100px;
  box-sizing: border-box;
}
.tl-timemarker .tl-timemarker-line-right {
  display: none;
  right: 0px;
}
.tl-timemarker .tl-timemarker-line-left {
  width: 1px;
  left: 0px;
}
.tl-timemarker .tl-timemarker-line-left,
.tl-timemarker .tl-timemarker-line-right {
  margin-top: 7px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  border-left: 1px solid #d9d9d9;
  z-index: 5;
  content: " ";
  position: absolute;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 1px 1px 1px #FFF;
}
.tl-timemarker .tl-timemarker-line-left:after,
.tl-timemarker .tl-timemarker-line-right:after {
  display: block;
  content: " ";
  position: absolute;
  left: -4px;
  bottom: 0px;
  height: 6px;
  width: 6px;
  background-color: #919191;
  z-index: 8;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.tl-timemarker .tl-timemarker-content-container {
  position: absolute;
  background-color: #e5e5e5;
  border: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  width: 100px;
  overflow: hidden;
  z-index: 6;
  -webkit-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px 1px #FFF;
}
.tl-timemarker .tl-timemarker-content-container:hover {
  z-index: 9;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content {
  position: relative;
  overflow: hidden;
  height: 100%;
  z-index: 8;
  padding: 5px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text {
  overflow: hidden;
  position: relative;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline,
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline p {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 12px;
  height: 100%;
  overflow: hidden;
  font-weight: normal;
  margin: 0;
  color: #bfbfbf;
  position: relative;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline.tl-headline-fadeout:after,
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline p.tl-headline-fadeout:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background: -moz-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229, 229, 229, 0)), color-stop(50%, #e5e5e5));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@thecolor', endColorstr='@thecolor', GradientType=0);
  /* IE6-9 */
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container {
  float: left;
  max-width: 24px;
  max-height: 24px;
  overflow: hidden;
  margin-right: 5px;
  height: 100%;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container .tl-timemarker-media {
  max-width: 24px;
  max-height: 100%;
  opacity: 0.25;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container [class^="tl-icon-"],
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container [class*=" tl-icon-"] {
  display: block;
  font-size: 24px;
  color: #bfbfbf;
  margin-top: 0px;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container .tl-icon-wikipedia {
  font-size: 16px;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content.tl-timemarker-content-small .tl-timemarker-text h2.tl-headline {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content.tl-timemarker-content-small .tl-timemarker-media-container [class^="tl-icon-"],
.tl-timemarker .tl-timemarker-content-container .tl-timemarker-content.tl-timemarker-content-small .tl-timemarker-media-container [class*=" tl-icon-"] {
  font-size: 12px;
}
.tl-timemarker:hover .tl-timemarker-timespan {
  background-color: rgba(191, 191, 191, 0.15);
}
.tl-timemarker:hover .tl-timemarker-timespan .tl-timemarker-timespan-content {
  background-color: #bfbfbf;
}
.tl-timemarker:hover .tl-timemarker-timespan .tl-timemarker-line-left,
.tl-timemarker:hover .tl-timemarker-timespan .tl-timemarker-line-right {
  border-color: #a6a6a6;
}
.tl-timemarker:hover .tl-timemarker-timespan .tl-timemarker-line-left:after,
.tl-timemarker:hover .tl-timemarker-timespan .tl-timemarker-line-right:after {
  background-color: #3d3d3d;
}
.tl-timemarker:hover .tl-timemarker-content-container {
  background-color: #bfbfbf;
  border-color: #a6a6a6;
  -webkit-transition: height 250ms cubic-bezier(0.77, 0, 0.175, 1), width 250ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: height 250ms cubic-bezier(0.77, 0, 0.175, 1), width 250ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: height 250ms cubic-bezier(0.77, 0, 0.175, 1), width 250ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: height 250ms cubic-bezier(0.77, 0, 0.175, 1), width 250ms cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timemarker:hover .tl-timemarker-content-container.tl-timemarker-content-container-small {
  width: 200px;
}
.tl-timemarker:hover .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline {
  color: #FFF;
}
.tl-timemarker:hover .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline.tl-headline-fadeout:after {
  background: -moz-linear-gradient(top, rgba(191, 191, 191, 0) 0%, #bfbfbf 80%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(191, 191, 191, 0)), color-stop(80%, #bfbfbf));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(191, 191, 191, 0) 0%, #bfbfbf 80%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(191, 191, 191, 0) 0%, #bfbfbf 80%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(191, 191, 191, 0) 0%, #bfbfbf 80%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(191, 191, 191, 0) 0%, #bfbfbf 80%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@thecolor', endColorstr='@thecolor', GradientType=0);
  /* IE6-9 */
}
.tl-timemarker:hover .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container .tl-timemarker-media {
  opacity: 1;
}
.tl-timemarker:hover .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container [class^="tl-icon-"],
.tl-timemarker:hover .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container [class*=" tl-icon-"] {
  color: #FFF;
}
.tl-timemarker:hover.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline.tl-headline-fadeout:after {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(80%, #FFF));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@thecolor', endColorstr='@thecolor', GradientType=0);
  /* IE6-9 */
}
.tl-timemarker:hover.tl-timemarker-active .tl-timemarker-line-left,
.tl-timemarker:hover.tl-timemarker-active .tl-timemarker-line-right {
  border-color: #000;
}
.tl-timemarker:hover.tl-timemarker-active .tl-timemarker-line-left:after,
.tl-timemarker:hover.tl-timemarker-active .tl-timemarker-line-right:after {
  background-color: #000;
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-timespan {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 8;
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-timespan .tl-timemarker-timespan-content {
  background-color: #333;
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-line-left,
.tl-timemarker.tl-timemarker-active .tl-timemarker-line-right {
  border-color: rgba(51, 51, 51, 0.5);
  border-width: 1px;
  z-index: 8;
  box-shadow: 0px 1px 3px rgba(145, 145, 145, 0.5);
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-line-left:after,
.tl-timemarker.tl-timemarker-active .tl-timemarker-line-right:after {
  background-color: #333;
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container {
  background-color: #FFF;
  color: #333;
  z-index: 9;
  border-color: rgba(51, 51, 51, 0.5);
  box-shadow: 1px 1px 3px rgba(145, 145, 145, 0.5);
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline {
  color: #333;
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline.tl-headline-fadeout:after {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(80%, #FFF));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFF 80%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@thecolor', endColorstr='@thecolor', GradientType=0);
  /* IE6-9 */
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container .tl-timemarker-media {
  opacity: 1;
}
.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container [class^="tl-icon-"],
.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container [class*=" tl-icon-"] {
  color: #333;
}
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-timespan .tl-timemarker-timespan-content {
  display: block;
}
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-timespan .tl-timemarker-line-left,
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-timespan .tl-timemarker-line-right {
  z-index: 5;
}
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-timespan:after {
  display: block;
  content: " ";
  position: absolute;
  left: 0px;
  bottom: -7px;
  height: 6px;
  width: 100%;
  background-color: rgba(115, 115, 115, 0.15);
  z-index: 6;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-content-container.tl-timemarker-content-container-long {
  box-shadow: none;
}
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-line-right {
  display: block;
}
.tl-timemarker.tl-timemarker-with-end .tl-timemarker-line-left {
  box-shadow: none;
}
.tl-timemarker.tl-timemarker-with-end:hover .tl-timemarker-timespan:after {
  background-color: rgba(0, 0, 0, 0.25);
}
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-timespan:after {
  background-color: rgba(51, 51, 51, 0.5);
}
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-left,
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-right {
  border-width: 1px;
}
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-left:after,
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-right:after {
  background-color: #333 !important;
}
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-left {
  box-shadow: none;
}
.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active:hover .tl-timemarker-timespan:after {
  background-color: rgba(51, 51, 51, 0.5);
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
/* TL.TimeMarker
================================================== */
.tl-timeera {
  height: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 3;
  /* Animate Left Width and Top
	================================================== */
  -webkit-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* Timespan
	================================================== */
  /* Content
	================================================== */
}
.tl-timeera.tl-timeera-fast {
  -webkit-transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timeera .tl-timeera-background {
  position: absolute;
  background-color: #28a6c3;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.tl-timeera.tl-timeera-color0 .tl-timeera-background {
  background-color: #c34528;
}
.tl-timeera.tl-timeera-color1 .tl-timeera-background {
  background-color: #28a6c3;
}
.tl-timeera.tl-timeera-color2 .tl-timeera-background {
  background-color: #2832c3;
}
.tl-timeera.tl-timeera-color3 .tl-timeera-background {
  background-color: #28c36c;
}
.tl-timeera.tl-timeera-color4 .tl-timeera-background {
  background-color: #286dc3;
}
.tl-timeera.tl-timeera-color5 .tl-timeera-background {
  background-color: #28c3a7;
}
.tl-timeera .tl-timeera-content-container {
  position: absolute;
  border: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  width: 100px;
  overflow: hidden;
  -webkit-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
}
.tl-timeera .tl-timeera-content-container .tl-timeera-content {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 5px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}
.tl-timeera .tl-timeera-content-container .tl-timeera-content .tl-timeera-text {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.tl-timeera .tl-timeera-content-container .tl-timeera-content .tl-timeera-text h2.tl-headline {
  bottom: 0px;
  position: absolute;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 10px;
  line-height: 10px;
  overflow: hidden;
  font-weight: normal;
  margin: 0;
  color: #FFF;
  margin-left: 10px;
}
.tl-timeera .tl-timeera-content-container .tl-timeera-content .tl-timeera-text h2.tl-headline.tl-headline-fadeout:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background: -moz-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229, 229, 229, 0)), color-stop(50%, #e5e5e5));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(229, 229, 229, 0) 0%, #e5e5e5 50%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@thecolor', endColorstr='@thecolor', GradientType=0);
  /* IE6-9 */
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
/* TL.TimeMarker
================================================== */
.tl-timegroup {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  /* Animate Left Width and Top
	================================================== */
  -webkit-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: left 1000ms cubic-bezier(0.77, 0, 0.175, 1), top 500ms cubic-bezier(0.77, 0, 0.175, 1), height 500ms cubic-bezier(0.77, 0, 0.175, 1), width 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timegroup .tl-timegroup-message {
  color: #e0e0e0;
  text-shadow: #FFF 0px 2px 2px;
  margin-left: 80px;
}
.tl-timegroup.tl-timegroup-alternate {
  background-color: #fafafa;
}
.tl-timegroup.tl-timegroup-hidden {
  display: none;
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
/* TL.TimeAxis
================================================== */
.tl-timeaxis-background {
  height: 39px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  border-top: 1px solid #e5e5e5;
  z-index: 2;
}
.tl-timeaxis {
  height: 39px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.tl-timeaxis .tl-timeaxis-content-container {
  position: relative;
  bottom: 0;
  height: 39px;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major,
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor {
  opacity: 0;
  position: absolute;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick,
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  text-align: center;
  font-weight: normal;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick .tl-timeaxis-tick-text,
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick .tl-timeaxis-tick-text {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick:before,
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick:before {
  content: "|";
  display: block;
  color: #FFF;
  width: 1px;
  overflow: hidden;
  border-left: 1px solid #bfbfbf;
  text-align: center;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major.tl-timeaxis-animate .tl-timeaxis-tick,
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor.tl-timeaxis-animate .tl-timeaxis-tick {
  -webkit-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major.tl-timeaxis-animate-opacity .tl-timeaxis-tick,
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor.tl-timeaxis-animate-opacity .tl-timeaxis-tick {
  -webkit-transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major {
  z-index: 1;
  background-color: #FFF;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick {
  font-size: 12px;
  line-height: 14px;
  color: #737373;
  width: 100px;
  margin-left: -50px;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick:before {
  border-color: #a6a6a6;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 2px;
  margin-left: 50px;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick {
  font-size: 11px;
  line-height: 13px;
  color: #bfbfbf;
  width: 50px;
  margin-left: -25px;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick .tl-timeaxis-tick-text {
  opacity: 0;
  white-space: normal;
  padding-left: 2px;
  padding-right: 2px;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick .tl-timeaxis-tick-text span {
  display: block;
  font-size: 9px;
  line-height: 9px;
  margin-top: -2px;
  color: #e6e6e6;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick:before {
  font-size: 9px;
  line-height: 9px;
  margin-left: 25px;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick.tl-timeaxis-tick-hidden .tl-timeaxis-tick-text {
  opacity: 0 !important;
}
.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-minor .tl-timeaxis-tick.tl-timeaxis-tick-hidden:before {
  opacity: 0.33;
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
.tlanimate {
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
.tl-animate {
  -webkit-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.tl-animate-opacity {
  -webkit-transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
/* SLIDE
================================================== */
.tl-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}
.tl-slide .tl-slide-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  display: none;
  filter: alpha(opacity=50);
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.tl-slide .tl-slide-scrollable-container {
  display: table;
  table-layout: fixed;
  height: 100%;
  z-index: 1;
}
.tl-slide .tl-slide-content-container {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.tl-slide .tl-slide-content-container .tl-slide-content {
  display: table;
  vertical-align: middle;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  max-width: 100%;
  user-select: text;
}
.tl-slide .tl-slide-content-container .tl-slide-content .tl-media {
  position: relative;
  width: 100%;
  min-width: 50%;
  float: left;
  margin-top: auto;
  margin-bottom: auto;
}
.tl-slide .tl-slide-content-container .tl-slide-content .tl-text {
  width: 50%;
  max-width: 50%;
  min-width: 120px;
  padding: 0 20px 0 20px;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
/* Only Media (no text)
================================================== */
.tl-slide-media-only .tl-slide-content-container .tl-slide-content {
  text-align: center;
}
.tl-slide-media-only .tl-slide-content-container .tl-slide-content .tl-media {
  text-align: center;
  position: relative;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  float: none;
  margin-top: auto;
  margin-bottom: auto;
}
.tl-slide-media-only .tl-slide-content-container .tl-slide-content .tl-text {
  width: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
/* Only Text (no media)
================================================== */
.tl-slide-text-only .tl-slide-content-container .tl-slide-content {
  text-align: center;
}
.tl-slide-text-only .tl-slide-content-container .tl-slide-content .tl-text {
  max-width: 80%;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* Background
================================================== */
.tl-slide.tl-full-image-background,
.tl-slide.tl-full-color-background {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background p,
.tl-slide.tl-full-color-background p,
.tl-slide.tl-full-image-background h1,
.tl-slide.tl-full-color-background h1,
.tl-slide.tl-full-image-background h2,
.tl-slide.tl-full-color-background h2,
.tl-slide.tl-full-image-background h3,
.tl-slide.tl-full-color-background h3,
.tl-slide.tl-full-image-background h4,
.tl-slide.tl-full-color-background h4,
.tl-slide.tl-full-image-background h5,
.tl-slide.tl-full-color-background h5,
.tl-slide.tl-full-image-background h6,
.tl-slide.tl-full-color-background h6 {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background a,
.tl-slide.tl-full-color-background a,
.tl-slide.tl-full-image-background b,
.tl-slide.tl-full-color-background b,
.tl-slide.tl-full-image-background i,
.tl-slide.tl-full-color-background i,
.tl-slide.tl-full-image-background blockquote,
.tl-slide.tl-full-color-background blockquote,
.tl-slide.tl-full-image-background blockquote p,
.tl-slide.tl-full-color-background blockquote p {
  text-shadow: 1px 1px 1px #000;
  color: #ffffff;
}
.tl-slide.tl-full-image-background a:hover,
.tl-slide.tl-full-color-background a:hover {
  text-decoration: underline;
  color: #c34528;
}
.tl-slide.tl-full-image-background .tl-caption,
.tl-slide.tl-full-color-background .tl-caption,
.tl-slide.tl-full-image-background .tl-credit,
.tl-slide.tl-full-color-background .tl-credit {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background .tl-media-twitter blockquote,
.tl-slide.tl-full-color-background .tl-media-twitter blockquote,
.tl-slide.tl-full-image-background .tl-media-blockquote blockquote,
.tl-slide.tl-full-color-background .tl-media-blockquote blockquote {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background .tl-media-twitter blockquote p,
.tl-slide.tl-full-color-background .tl-media-twitter blockquote p,
.tl-slide.tl-full-image-background .tl-media-blockquote blockquote p,
.tl-slide.tl-full-color-background .tl-media-blockquote blockquote p {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background .vcard a,
.tl-slide.tl-full-color-background .vcard a,
.tl-slide.tl-full-image-background .vcard .nickname,
.tl-slide.tl-full-color-background .vcard .nickname {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
/* Full Image Background
================================================== */
.tl-slide.tl-full-image-background {
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center 25%;
  text-shadow: 1px 1px 2px #000;
}
.tl-slide.tl-full-image-background p,
.tl-slide.tl-full-image-background h1,
.tl-slide.tl-full-image-background h2,
.tl-slide.tl-full-image-background h3,
.tl-slide.tl-full-image-background h4,
.tl-slide.tl-full-image-background h5,
.tl-slide.tl-full-image-background h6 {
  text-shadow: 1px 1px 2px #000;
}
.tl-slide.tl-full-image-background .tl-caption,
.tl-slide.tl-full-image-background .tl-credit {
  text-shadow: 1px 1px 2px #000;
}
.tl-slide.tl-full-image-background .tl-media-twitter blockquote,
.tl-slide.tl-full-image-background .tl-media-blockquote blockquote {
  text-shadow: 1px 1px 2px #000 !important;
}
.tl-slide.tl-full-image-background .tl-media-twitter blockquote p,
.tl-slide.tl-full-image-background .tl-media-blockquote blockquote p {
  text-shadow: 1px 1px 2px #000 !important;
}
/* Color Background
================================================== */
/* Text Background
================================================== */
.tl-slide.tl-text-background .tl-text .tl-text-content-container {
  padding: 20px;
  /* Fallback for web browsers that doesn't support RGBa */
  background: #000000 transparent;
  /* RGBa with 0.6 opacity */
  background: rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.tl-slide.tl-text-background .tl-text .tl-text-content-container h2 {
  margin-top: 5px;
}
/* Skinny
================================================== */
.tl-skinny .tl-slide {
  display: block;
  padding-top: 10px;
}
.tl-skinny .tl-slide .tl-slide-content-container {
  display: block;
  position: static;
  height: auto;
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  /* Safari 7.0+ */
}
.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content {
  display: block;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  /* Safari */
  position: static;
  height: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media {
  position: static;
  width: 100%;
  height: auto;
  float: none;
  display: block;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}
.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-text {
  display: block;
  height: auto;
  vertical-align: initial;
  position: static;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  float: none;
  padding: 0;
}
.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-text .tl-text-content-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.tl-skinny .tl-slide.tl-slide.tl-full-color-background .tl-slide-content-container .tl-slide-content .tl-media,
.tl-skinny .tl-slide.tl-full-image-background .tl-slide-content-container .tl-slide-content .tl-media {
  border-color: rgba(230, 230, 230, 0.25);
}
.tl-skinny .tl-slide.tl-slide-media-only .tl-slide-content-container .tl-slide-content {
  flex-direction: column;
  -webkit-flex-direction: column;
  /* Safari */
}
.tl-skinny .tl-slide.tl-slide-media-only .tl-slide-content-container .tl-slide-content .tl-media {
  border-top: none;
  padding-top: 0px;
}
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media img,
.tl-mobile.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media embed,
.tl-mobile.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media object,
.tl-mobile.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media video,
.tl-mobile.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media iframe {
  max-height: 175px;
}
/* SlideNav
================================================== */
/* NAVIGATION
================================================== */
.tl-slidenav-previous,
.tl-slidenav-next {
  position: absolute;
  top: 45%;
  z-index: 10;
  cursor: pointer;
}
.tl-slidenav-previous .tl-slidenav-content-container,
.tl-slidenav-next .tl-slidenav-content-container {
  height: 200px;
  width: 100px;
  position: absolute;
}
.tl-slidenav-previous .tl-slidenav-title,
.tl-slidenav-next .tl-slidenav-title,
.tl-slidenav-previous .tl-slidenav-description,
.tl-slidenav-next .tl-slidenav-description {
  width: 80px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  /* Non standard for webkit */
  /*
	   -webkit-hyphens: auto;
	      -moz-hyphens: auto;
	       -ms-hyphens: auto;
	           hyphens: auto;
		*/
  filter: alpha(opacity=15);
  -khtml-opacity: 0.15;
  -moz-opacity: 0.15;
  opacity: 0.15;
}
.tl-slidenav-previous .tl-slidenav-title small,
.tl-slidenav-next .tl-slidenav-title small,
.tl-slidenav-previous .tl-slidenav-description small,
.tl-slidenav-next .tl-slidenav-description small {
  display: block;
}
.tl-slidenav-previous .tl-slidenav-title,
.tl-slidenav-next .tl-slidenav-title {
  margin-top: 10px;
  font-size: 11px;
  line-height: 11px;
}
.tl-slidenav-previous .tl-slidenav-description,
.tl-slidenav-next .tl-slidenav-description {
  font-size: 11px;
  margin-top: 5px;
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
.tl-slidenav-previous .tl-slidenav-description small,
.tl-slidenav-next .tl-slidenav-description small {
  display: none;
}
/* NAVIGATION COLOR
================================================== */
.tl-slidenav-previous .tl-slidenav-content-container .tl-slidenav-icon,
.tl-slidenav-next .tl-slidenav-content-container .tl-slidenav-icon,
.tl-slidenav-previous .tl-slidenav-content-container .tl-slidenav-title,
.tl-slidenav-next .tl-slidenav-content-container .tl-slidenav-title,
.tl-slidenav-previous .tl-slidenav-content-container .tl-slidenav-description,
.tl-slidenav-next .tl-slidenav-content-container .tl-slidenav-description {
  text-shadow: 1px 1px 1px #FFF;
  color: #333;
}
.tl-slidenav-previous .tl-slidenav-content-container.tl-slidenav-inverted .tl-slidenav-icon,
.tl-slidenav-next .tl-slidenav-content-container.tl-slidenav-inverted .tl-slidenav-icon,
.tl-slidenav-previous .tl-slidenav-content-container.tl-slidenav-inverted .tl-slidenav-title,
.tl-slidenav-next .tl-slidenav-content-container.tl-slidenav-inverted .tl-slidenav-title,
.tl-slidenav-previous .tl-slidenav-content-container.tl-slidenav-inverted .tl-slidenav-description,
.tl-slidenav-next .tl-slidenav-content-container.tl-slidenav-inverted .tl-slidenav-description {
  color: #f2f2f2;
  text-shadow: 1px 1px 1px #333;
}
/* ICONS
================================================== */
.tl-slidenav-next .tl-slidenav-icon,
.tl-slidenav-previous .tl-slidenav-icon {
  font-family: 'tl-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
  margin-bottom: 5px;
}
.tl-slidenav-next {
  text-align: right;
  margin-right: 10px;
  right: 100px;
}
.tl-slidenav-next .tl-slidenav-title,
.tl-slidenav-next .tl-slidenav-description {
  margin-left: 20px;
}
.tl-slidenav-next .tl-slidenav-icon {
  margin-left: 76px;
}
.tl-slidenav-next .tl-slidenav-icon:before {
  content: "\e64f";
}
.tl-slidenav-previous {
  text-align: left;
  margin-left: 10px;
}
.tl-slidenav-previous .tl-slidenav-icon {
  margin-left: 0px;
}
.tl-slidenav-previous .tl-slidenav-icon:before {
  content: "\e650";
}
/* NAVIGATION HOVER
================================================== */
.tl-slidenav-previous:hover .tl-slidenav-title,
.tl-slidenav-next:hover .tl-slidenav-title {
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-slidenav-previous:hover .tl-slidenav-description,
.tl-slidenav-next:hover .tl-slidenav-description {
  filter: alpha(opacity=50);
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
}
.tl-slidenav-next:hover .tl-slidenav-icon {
  margin-left: 80px;
}
.tl-slidenav-previous:hover .tl-slidenav-icon {
  margin-left: -4px;
}
.tl-skinny .tl-slidenav-next {
  right: 32px;
}
.tl-skinny .tl-slidenav-next .tl-slidenav-icon {
  margin-left: 8px;
}
.tl-skinny .tl-slidenav-previous .tl-slidenav-content-container,
.tl-skinny .tl-slidenav-next .tl-slidenav-content-container {
  width: 32px;
  height: 32px;
}
.tl-skinny .tl-slidenav-previous .tl-slidenav-title,
.tl-skinny .tl-slidenav-next .tl-slidenav-title,
.tl-skinny .tl-slidenav-previous .tl-slidenav-description,
.tl-skinny .tl-slidenav-next .tl-slidenav-description {
  display: none;
}
.tl-skinny .tl-slidenav-previous .tl-slidenav-icon,
.tl-skinny .tl-slidenav-next .tl-slidenav-icon {
  filter: alpha(opacity=33);
  -khtml-opacity: 0.33;
  -moz-opacity: 0.33;
  opacity: 0.33;
}
.tl-skinny .tl-slidenav-next:hover .tl-slidenav-icon {
  margin-left: 12px;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-skinny .tl-slidenav-previous:hover .tl-slidenav-icon {
  margin-left: -4px;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-layout-landscape.tl-mobile .tl-slidenav-next:hover {
  right: 70px;
}
.tl-layout-landscape.tl-mobile .tl-slidenav-next:hover .tl-slidenav-icon {
  margin-left: 8px;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-layout-landscape.tl-mobile .tl-slidenav-next:active .tl-slidenav-icon {
  margin-left: 0px;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-layout-landscape.tl-mobile .tl-slidenav-previous:hover .tl-slidenav-icon {
  margin-left: 80px;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-layout-landscape.tl-mobile .tl-slidenav-previous:active .tl-slidenav-icon {
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  margin-left: -4px;
}
.tl-layout-portrait.tl-mobile .tl-slidenav-next:hover .tl-slidenav-icon {
  filter: alpha(opacity=33);
  -khtml-opacity: 0.33;
  -moz-opacity: 0.33;
  opacity: 0.33;
}
.tl-layout-portrait.tl-mobile .tl-slidenav-next:active .tl-slidenav-icon {
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-layout-portrait.tl-mobile .tl-slidenav-previous:hover .tl-slidenav-icon {
  filter: alpha(opacity=33);
  -khtml-opacity: 0.33;
  -moz-opacity: 0.33;
  opacity: 0.33;
}
.tl-layout-portrait.tl-mobile .tl-slidenav-previous:active .tl-slidenav-icon {
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.tl-mobile .tl-slidenav-previous,
.tl-skinny.tl-mobile .tl-slidenav-previous,
.tl-skinny.tl-layout-landscape.tl-mobile .tl-slidenav-previous,
.tl-skinny.tl-layout-portrait.tl-mobile .tl-slidenav-previous,
.tl-mobile .tl-slidenav-next,
.tl-skinny.tl-mobile .tl-slidenav-next,
.tl-skinny.tl-layout-landscape.tl-mobile .tl-slidenav-next,
.tl-skinny.tl-layout-portrait.tl-mobile .tl-slidenav-next {
  display: none;
}
/* StorySlider
================================================== */
/* SLIDER CONTAINERS
================================================== */
.tl-storyslider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  position: relative;
  box-sizing: content-box;
  z-index: 8;
}
.tl-storyslider img,
.tl-storyslider embed,
.tl-storyslider object,
.tl-storyslider video,
.tl-storyslider iframe {
  max-width: 100%;
  position: relative;
}
.tl-storyslider .tl-slider-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.tl-storyslider .tl-slider-touch-mask {
  width: 100%;
  height: 100%;
  z-index: 25;
  top: 0px;
  left: 0px;
  position: absolute;
}
.tl-storyslider .tl-slider-container-mask {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
}
.tl-storyslider .tl-slider-container-mask .tl-slider-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
}
.tl-storyslider .tl-slider-container-mask .tl-slider-container .tl-slider-item-container {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
/* Skinny
================================================== */
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
/* Requires Variables.less
================================================== */
.tl-media {
  width: 100%;
  min-width: 50%;
  height: 100%;
  float: left;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}
.tl-media .tl-media-content-container.tl-media-content-container-text {
  border-right: 1px solid #e6e6e6;
  padding-right: 20px;
}
.tl-media .tl-media-content-container .tl-media-content {
  position: relative;
  *zoom: 1;
}
.tl-media .tl-media-content-container .tl-media-content:before,
.tl-media .tl-media-content-container .tl-media-content:after {
  display: table;
  content: "";
}
.tl-media .tl-media-content-container .tl-media-content:after {
  clear: both;
}
.tl-media .tl-media-content-container .tl-media-content .tl-media-loaderror p {
  color: #f2f2f2;
  text-align: center;
}
.tl-media .tl-media-content-container .tl-media-content .tl-media-loaderror p span {
  color: #f2f2f2;
}
.tl-media .tl-media-content-container .tl-media-content .tl-media-loaderror [class^="tl-icon-"],
.tl-media .tl-media-content-container .tl-media-content .tl-media-loaderror [class*=" tl-icon-"] {
  font-size: 28px;
  color: #f2f2f2;
  text-align: center;
}
.tl-media .tl-media-content-container .tl-media-content img,
.tl-media .tl-media-content-container .tl-media-content embed,
.tl-media .tl-media-content-container .tl-media-content object,
.tl-media .tl-media-content-container .tl-media-content video {
  max-width: 100%;
  max-height: 100%;
}
/* Media Only Slides
================================================== */
.tl-slide-media-only .tl-media .tl-media-content-container.tl-media-content-container-text {
  border-right: none;
  padding-right: 0;
}
/* Media Shodow
================================================== */
.tl-media-shadow {
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.6);
  box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.6);
}
.tl-slide.tl-full-image-background a,
.tl-slide.tl-full-color-background a,
.tl-slide.tl-full-image-background .vcard a,
.tl-slide.tl-full-color-background .vcard a {
  text-shadow: 1px 1px 1px #000;
  color: #ffffff;
}
.tl-slide.tl-full-image-background a:hover,
.tl-slide.tl-full-color-background a:hover {
  text-decoration: underline;
  color: #c34528;
}
/* Credit
================================================== */
.tl-credit {
  color: #999999;
  text-align: right;
  display: block;
  margin: 0 auto;
  margin-top: 6px;
  font-size: 10px;
  line-height: 13px;
}
/* Caption
================================================== */
.tl-caption {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  color: #666666;
  font-size: 11px;
  line-height: 14px;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}
/* Full Image Background
================================================== */
.tl-full-image-background .tl-media-shadow:before,
.tl-full-color-background .tl-media-shadow:before,
.tl-full-image-background .tl-media-shadow:after,
.tl-full-color-background .tl-media-shadow:after {
  background: none;
  -webkit-box-shadow: 0 0px 0px #000;
  -moz-box-shadow: 0 0px 0px #000;
  box-shadow: 0 0px 0px #000;
}
/* Skinny
================================================== */
.tl-skinny .tl-media {
  width: 100%;
  height: auto;
  float: none;
  display: block;
}
.tl-skinny .tl-media .tl-media-content-container.tl-media-content-container-text {
  border-right: 0;
  padding-right: 0;
}
.tl-skinny .tl-media .tl-media-content-container .tl-credit,
.tl-skinny .tl-media .tl-media-content-container .tl-caption {
  margin-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 8px;
}
.tl-skinny .tl-media .tl-media-content-container .tl-credit {
  margin-top: 0px;
}
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny .tl-media {
  width: 100%;
  height: auto;
  float: none;
  display: block;
}
.tl-mobile.tl-skinny .tl-media .tl-media-content-container.tl-media-content-container-text {
  border-right: 0;
  padding-right: 0;
}
/* Requires Variables.less
================================================== */
.tl-text {
  width: 50%;
  max-width: 50%;
  min-width: 120px;
  padding: 0 20px 0 20px;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  text-shadow: none;
  color: #737373;
}
.tl-text p {
  color: #737373;
}
.tl-text h2.tl-headline-title,
.tl-text h2.tl-headline {
  margin-top: 0;
}
.tl-text .tl-headline-date,
.tl-text h3.tl-headline-date {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 15px;
  font-weight: normal;
  margin: 0 0 3px 0;
  color: #b3b3b3;
}
.tl-text .tl-headline-date small,
.tl-text h3.tl-headline-date small {
  font-size: 15px;
  line-height: 15px;
  font-weight: normal;
  color: #b3b3b3;
}
.tl-text .tl-text-date {
  display: inline-block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 10px;
  font-size: 12px;
  color: #b3b3b3;
}
.tl-full-image-background .tl-text,
.tl-full-color-background .tl-text,
.tl-full-image-background .tl-text p,
.tl-full-color-background .tl-text p {
  color: #f2f2f2 !important;
  text-shadow: 1px 1px 2px #000;
}
.tl-full-image-background .tl-text .tl-headline-date,
.tl-full-color-background .tl-text .tl-headline-date,
.tl-full-image-background .tl-text p .tl-headline-date,
.tl-full-color-background .tl-text p .tl-headline-date,
.tl-full-image-background .tl-text h3.tl-headline-date,
.tl-full-color-background .tl-text h3.tl-headline-date,
.tl-full-image-background .tl-text p h3.tl-headline-date,
.tl-full-color-background .tl-text p h3.tl-headline-date {
  color: #f2f2f2 !important;
}
.tl-full-image-background .tl-text .tl-headline-date small,
.tl-full-color-background .tl-text .tl-headline-date small,
.tl-full-image-background .tl-text p .tl-headline-date small,
.tl-full-color-background .tl-text p .tl-headline-date small,
.tl-full-image-background .tl-text h3.tl-headline-date small,
.tl-full-color-background .tl-text h3.tl-headline-date small,
.tl-full-image-background .tl-text p h3.tl-headline-date small,
.tl-full-color-background .tl-text p h3.tl-headline-date small {
  color: #f2f2f2 !important;
}
.tl-full-image-background .tl-text a:hover,
.tl-full-color-background .tl-text a:hover,
.tl-full-image-background .tl-text p a:hover,
.tl-full-color-background .tl-text p a:hover {
  text-decoration: underline;
  color: #c34528;
}
/* Skinny
================================================== */
.tl-skinny .tl-text {
  width: 100%;
  max-width: 100%;
  min-width: auto;
  float: none;
  margin-top: 20px;
}
.tl-skinny .tl-text h2.tl-headline-title,
.tl-skinny .tl-text h2.tl-headline {
  font-size: 32px;
  line-height: 36px;
}
/* Medium
================================================== */
.tl-medium .tl-text h2.tl-headline-title,
.tl-medium .tl-text h2.tl-headline {
  font-size: 32px;
  line-height: 36px;
}
/* Mobile, iPhone
================================================== */
/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny .tl-media .tl-media-image {
  max-height: 250px !important;
}
.tl-media .tl-media-twitter {
  text-align: left;
  clear: both;
}
.tl-media .tl-media-twitter blockquote {
  margin: 0;
  margin-right: 15px;
  font-size: 15px;
  line-height: 21px;
  color: #333;
}
.tl-media .tl-media-twitter blockquote p {
  font-size: 28px;
  line-height: 30px;
  color: #000;
}
.tl-media .tl-media-twitter blockquote p:before {
  display: none;
}
.tl-media .tl-media-twitter blockquote p:after {
  display: none;
}
.tl-media .tl-media-twitter .tl-icon-twitter {
  color: #55ACEE;
}
.tl-media .tl-media-twitter .vcard a:hover,
.tl-media .tl-media-twitter .vcard a.tl-date:hover {
  text-decoration: none;
  color: #55ACEE;
}
.tl-media .tl-media-twitter .vcard a:hover .fn,
.tl-media .tl-media-twitter .vcard a.tl-date:hover .fn,
.tl-media .tl-media-twitter .vcard a:hover .nickname,
.tl-media .tl-media-twitter .vcard a.tl-date:hover .nickname {
  color: #55ACEE;
}
.tl-slide-media-only .tl-media .tl-media-twitter {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.tl-mobile.tl-skinny .tl-media .tl-media-twitter blockquote p {
  font-size: 15px;
  line-height: 21px;
}
.tl-skinny .tl-media .tl-media-twitter {
  margin-left: 10px;
  margin-right: 10px;
}
.tl-skinny .tl-media .tl-media-twitter blockquote p {
  font-size: 24px;
  line-height: 26px;
}
.tl-media .tl-media-blockquote {
  text-align: left;
  clear: both;
}
.tl-media .tl-media-blockquote blockquote {
  margin: 0;
  margin-right: 15px;
  text-align: left;
  font-size: 28px;
  line-height: 30px;
  color: #333;
}
.tl-media .tl-media-blockquote blockquote p {
  font-size: 28px;
  line-height: 30px;
  color: #333;
}
.tl-media .tl-media-blockquote blockquote p:before,
.tl-media .tl-media-blockquote blockquote p:after {
  display: inline-block;
  font-size: 36px;
}
.tl-media .tl-media-blockquote blockquote p:before {
  content: open-quote;
  margin-right: 5px;
}
.tl-media .tl-media-blockquote blockquote p:after {
  content: close-quote;
  margin-left: 3px;
}
.tl-media .tl-media-blockquote blockquote cite {
  font-size: 15px;
  line-height: 21px;
  color: #999999;
  text-align: right;
  margin-top: 15px;
}
.tl-slide-media-only .tl-media .tl-media-blockquote {
  border-right: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 800px), only screen and (max-device-width: 800px) {
  .tl-media .tl-media-blockquote blockquote p {
    font-size: 24px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 500px), only screen and (max-device-width: 500px) {
  .tl-media .tl-media-blockquote blockquote p {
    font-size: 15px;
    line-height: 21px;
  }
}
.tl-mobile.tl-skinny .tl-media .tl-media-instagram {
  max-height: 250px !important;
}
.tl-media .tl-media-profile {
  border-radius: 50%;
}
.tl-media .tl-media-iframe {
  width: 100%;
  height: 100%;
}
.tl-media .tl-media-iframe iframe {
  width: 100%;
  height: 100%;
}
.tl-media .tl-media-wikipedia {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}
.tl-media .tl-media-wikipedia .tl-icon-wikipedia {
  font-size: 32px;
  margin-right: 10px;
  float: left;
  padding-top: 3px;
}
.tl-media .tl-media-wikipedia .tl-wikipedia-pageimage {
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.tl-media .tl-media-wikipedia .tl-wikipedia-title {
  margin-left: 60px;
  padding-left: 10px;
  border-left: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.tl-media .tl-media-wikipedia .tl-wikipedia-source {
  font-size: 13px;
  line-height: 15px;
  font-style: italic;
  margin-top: 3px;
  display: block;
  color: rgba(0, 0, 0, 0.5);
}
.tl-media .tl-media-wikipedia h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.tl-media .tl-media-wikipedia h4 a {
  color: #000;
  text-decoration: none;
}
.tl-media .tl-media-wikipedia p {
  font-size: 13px;
  line-height: 19px;
}
.tl-slide-media-only .tl-media .tl-media-wikipedia {
  border-right: 0;
  border-top: 1px solid #e6e6e6;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  padding-top: 25px;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-wikipedia,
.tl-slide.tl-full-color-background .tl-media .tl-media-wikipedia {
  padding: 20px;
  /* Fallback for web browsers that doesn't support RGBa */
  background: #000000 transparent;
  /* RGBa with 0.6 opacity */
  background: rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-wikipedia h4 a,
.tl-slide.tl-full-color-background .tl-media .tl-media-wikipedia h4 a {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-wikipedia a:hover,
.tl-slide.tl-full-color-background .tl-media .tl-media-wikipedia a:hover {
  text-decoration: underline;
  color: #c34528;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-wikipedia .tl-wikipedia-title,
.tl-slide.tl-full-color-background .tl-media .tl-media-wikipedia .tl-wikipedia-title {
  border-color: rgba(230, 230, 230, 0.25);
}
.tl-slide.tl-full-image-background .tl-media .tl-media-wikipedia .tl-wikipedia-source,
.tl-slide.tl-full-color-background .tl-media .tl-media-wikipedia .tl-wikipedia-source {
  color: rgba(230, 230, 230, 0.85);
}
.tl-mobile.tl-skinny .tl-media .tl-media-wikipedia,
.tl-skinny .tl-media .tl-media-wikipedia {
  margin-left: 10px;
  margin-right: 10px;
}
.tl-media .tl-media-website {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}
.tl-media .tl-media-website .tl-media-website-description {
  font-size: 16px;
  line-height: 19px;
  font-style: italic;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.tl-media .tl-media-website h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1;
}
.tl-media .tl-media-website h4 a {
  color: #000;
  text-decoration: none;
}
.tl-media .tl-media-website p {
  font-size: 13px;
  line-height: 19px;
}
.tl-media .tl-media-content-container .tl-media-content .tl-media-website img {
  float: right;
  max-width: 120px;
  max-height: 120px;
  margin: 4px 0 0 15px;
}
.tl-media .tl-media-content-container .tl-media-content .tl-media-website img.tl-media-website-icon {
  max-width: 16px;
  max-height: 16px;
  float: none;
  margin: 0;
  margin-right: 3px;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-website,
.tl-slide.tl-full-color-background .tl-media .tl-media-website {
  padding: 20px;
  /* Fallback for web browsers that doesn't support RGBa */
  background: #000000 transparent;
  /* RGBa with 0.6 opacity */
  background: rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-website h4 a,
.tl-slide.tl-full-color-background .tl-media .tl-media-website h4 a {
  color: #FFF !important;
  text-shadow: 1px 1px 1px #000 !important;
}
.tl-slide.tl-full-image-background .tl-media .tl-media-website a:hover,
.tl-slide.tl-full-color-background .tl-media .tl-media-website a:hover {
  text-decoration: underline;
  color: #c34528;
}
.tl-mobile.tl-skinny .tl-media .tl-media-website {
  margin-left: 10px;
  margin-right: 10px;
}
/* Timeline
================================================== */
.tl-timeline {
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: normal;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #FFF;
  color: #737373;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.tl-timeline.tl-timeline-embed {
  box-sizing: border-box;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
}
.tl-timeline.tl-timeline-full-embed {
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
}
/* Portrait
================================================== */
.tl-layout-portrait .tl-storyslider {
  -webkit-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.2);
}
.tl-rtl .tl-text-content,
.tl-rtl .tl-headline,
.tl-rtl .tl-media-blockquote,
.tl-rtl .tl-headline-date,
.tl-rtl .tl-timeline blockquote p,
.tl-rtl .tl-media-website,
.tl-rtl .tl-media-wikipedia,
.tl-rtl .tl-media .tl-media-blockquote blockquote,
.tl-rtl .blockquote,
.tl-rtl blockquote p,
.tl-rtl .tl-text-content p {
  text-align: right;
  direction: rtl;
}
.tl-rtl .tl-slide-media-only .tl-headline,
.tl-rtl .tl-slide-media-only .tl-headline-date {
  text-align: center;
}
.tl-rtl .tl-timemarker-text {
  margin-right: 35px;
}
.tl-rtl .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-media-container {
  float: right;
}
.tl-rtl .tl-caption {
  text-align: right;
}
.tl-rtl .tl-credit {
  text-align: left;
}
