/* Icons
================================================== */

@font-face {
	font-family: 'tl-icons';
	src:url('@{icon-path}tl-icons.eot');
	src:url('@{icon-path}tl-icons.eot?#iefix') format('embedded-opentype'),
		url('@{icon-path}tl-icons.ttf') format('truetype'),
		url('@{icon-path}tl-icons.woff2') format('woff2'),
		url('@{icon-path}tl-icons.woff') format('woff'),
		url('@{icon-path}tl-icons.svg#tl-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="tl-icon-"], [class*=" tl-icon-"] {
	font-family: 'tl-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.tl-icon-vine:after {
	content: "\e64d";
}
.tl-icon-wikipedia:after {
	content: "\e64e";
}
.tl-icon-chevron-right:after {
	content: "\e64f";
}
.tl-icon-chevron-left:after {
	content: "\e650";
}
.tl-icon-youtube-logo:after {
	content: "\e651";
}
.tl-icon-foursquare:after {
	content: "\e652";
}
.tl-icon-camera-retro:after {
	content: "\e653";
}
.tl-icon-doc:after {
	content: "\e654";
}
.tl-icon-weibo:after {
	content: "\e655";
}
.tl-icon-resize-horizontal:after {
	content: "\e656";
}
.tl-icon-resize-vertical:after {
	content: "\e657";
}
.tl-icon-resize-full:after {
	content: "\e658";
}
.tl-icon-resize-small:after {
	content: "\e659";
}
.tl-icon-twitter:after {
	content: "\e62b";
}
.tl-icon-google-plus:after {
	content: "\e62c";
}
.tl-icon-video:after {
	content: "\e62d";
}
.tl-icon-youtube:after {
	content: "\e62d";
}
.tl-icon-plaintext:after {
	content: "\e62e";
}
.tl-icon-storify:after {
	content: "\e62e";
}
.tl-icon-image-v2:after {
	content: "\e62f";
}
.tl-icon-quote-v2:after {
	content: "\e630";
}
.tl-icon-zoom-in:after {
	content: "\e631";
}
.tl-icon-zoom-out:after {
	content: "\e632";
}
.tl-icon-list:after {
	content: "\e633";
}
.tl-icon-music:after {
	content: "\e634";
}
.tl-icon-spotify:after {
	content: "\e634";
}
.tl-icon-location:after {
	content: "\e635";
}
.tl-icon-googlemaps:after {
	content: "\e635";
}
.tl-icon-web:after {
	content: "\e636";
}
.tl-icon-share-v2:after {
	content: "\e637";
}
.tl-icon-soundcloud:after {
	content: "\e639";
}
.tl-icon-video-v2:after {
	content: "\e63a";
}
.tl-icon-dailymotion:after {
	content: "\e63a";
}
.tl-icon-tumblr:after {
	content: "\e63b";
}
.tl-icon-lastfm:after {
	content: "\e63c";
}
.tl-icon-github:after {
	content: "\e63d";
}
.tl-icon-goback:after {
	content: "\e63e";
}
.tl-icon-doc-v2:after {
	content: "\e63f";
}
.tl-icon-googledrive:after {
	content: "\e640";
}
.tl-icon-facebook:after {
	content: "\e641";
}
.tl-icon-flickr:after {
	content: "\e642";
}
.tl-icon-dribbble:after {
	content: "\e643";
}
.tl-icon-image:after {
	content: "\e605";
}
.tl-icon-vimeo:after {
	content: "\e606";
}
.tl-icon-instagram:after {
	content: "\e644";
}
.tl-icon-pinterest:after {
	content: "\e645";
}
.tl-icon-arrow-left:after {
	content: "\e646";
}
.tl-icon-arrow-down:after {
	content: "\e647";
}
.tl-icon-arrow-up:after {
	content: "\e648";
}
.tl-icon-arrow-right:after {
	content: "\e649";
}
.tl-icon-share:after {
	content: "\e64a";
}
.tl-icon-blockquote:after {
	content: "\e64b";
}
.tl-icon-evernote:after {
	content: "\e64c";
}
.tl-icon-mappin:after {
	content: "\e600";
}
.tl-icon-swipe-right:after {
	content: "\e601";
}
.tl-icon-swipe-left:after {
	content: "\e602";
}
.tl-icon-touch-spread:after {
	content: "\e603";
}
.tl-icon-touch-pinch:after {
	content: "\e604";
}
